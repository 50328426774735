<template>
    <v-container>
        <v-row 
            id="main" style="min-height:70vh;"
            class="text-center"
            justify="center">
            <v-col cols="12"
                lg="10"
                md="10"
                sm="12">
                <h1 v-show="titre && titre != ''" style="font-size: 1.08em; font-weight:bold; text-align: left;">{{ titre }}</h1>
                <div class="text-justify" style="margin-bottom: 27px">
                  Prenez un covoiturage pas cher en {{ nom_departement }} ({{ code_departement }}) et partout en France avec un service innovant.
                </div>
                        <router-link class="btn" :to="{ name: 'inscription'}" style="color:white">
                          Rejoins la communauté
                        </router-link>

                <v-row class="covoiturages" v-show="villes.length > 0">
                    <v-col
                        cols="6"
                        v-for="ville in villes"
                        :key="ville.id">
                        <a :href="'https://www.simoneverte.fr/covoiturages/' + ville.nom_commune_complet "> {{ ville.nom_commune_complet }} </a>
                    </v-col>
                </v-row>
                <v-row class="covoiturages">
                    <v-col
                        cols="12"
                        style="margin-top: 18px; margin-bottom: 34px;">
                        <i v-if="code_departement != null">Voyagez en covoiturage pas cher en {{ nom_departement }} ({{ code_departement }}).</i>
                        <br />
                        <br />
                        <router-link class="btn" :to="{ name: 'proposer'}" style="color:white">
                          Proposer un trajet
                        </router-link>
                    </v-col>
                </v-row>
                <v-row 
                    justify="center"
                    style="margin-top: 36px">
                    
                    <v-col
                      cols="12"
                      lg="6"
                      md="6"
                      sm="12">

                      <div 
                        v-on:click="goToProposerTrajet()">

                        <img src="@/assets/img/banniere_commission.webp" style="width: 100%; cursor:pointer" />

                      </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import router from '../router';

export default {
  data() {
    return {
      titre: '',
      code_departement: null,
      nom_departement: '',
      villes: []
    }
  },
  mounted() {
    // Titre de l'onglet
    var description = '';
    
    // Cas de la route covoiturages-vers-ville
    if (this.$route.params.code_departement) {
      this.code_departement = this.$route.params.code_departement;
    }

    // Appel de la recherche de trajet correspondant aux criteres
    if (this.code_departement) {

      this.$root.$emit("start_loading");
      var url_recherche = '/villes/' + this.code_departement;

      // Appel de la recherche de trajet correspondant aux criteres
      this.$api.get(url_recherche)
      .then(res => {
        this.villes = res.data;
        if (this.villes.length > 0)
          this.nom_departement = this.villes[0].departement_nom;
        //
        this.titre = 'Voyagez en covoiturage en ' + this.nom_departement + ' (' + this.code_departement + ')';
        document.title = 'Voyagez en covoiturage en ' + this.nom_departement + ' (' + this.code_departement + ')';
        description = 'Voyagez pas cher en covoiturage en ' + this.nom_departement + ' (' + this.code_departement + ')';

        // document.title += ' | SimoneVerte.fr';
        description += ', notre service déssert particulièrement ' + this.villes.slice(0, 12).join(', ') + ' et bien d\'autres communes.'
        description += 'Prenez la route en toute confiance.';

      })
      .catch(err => {
        console.error(err);
        
        this.titre = 'Covoiturage dans le ' + this.code_departement;
        document.title = 'Covoiturage dans le ' + this.code_departement;
        description = 'Voyagez pas cher en covoiturage dans le ' + this.code_departement;

        // document.title += ' | SimoneVerte.fr';
        description += '. Prenez la route en toute confiance.';

      })
      .finally(() => {
        this.$root.$emit("hide_loading");
        this.$root.$emit("change_meta_description", description);
      });
    }
  },
  unmounted() {
    this.$root.$emit("reset_meta_description");
  },
  methods: {
    goToProposerTrajet: function() {
      router.push({ name: 'proposer'});
    }
  }
}
</script>

<style>
.entete {
  font-size: 0.90em;
  border-radius: 12px;
  margin: 12px 0px;
  background-color: #ddf;
}
.entete .information-recherche {
  font-style: italic;
}
.entete .calendrier-date {
  color: #fff;
  background: #20c997;
  border-color: #20c997;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: 12px;
  padding: 12px 4px;
}
.entete .calendrier-date.before:hover,
.entete .calendrier-date.after:hover {
  font-weight: bold;
  cursor: pointer;
}
.entete .calendrier-date.now {
  height: 63px;
  border-left: 1px solid;
  border-right: 1px solid;
  padding-top: 1.2rem;
  cursor: auto;
}
.entete .calendrier-date.before,
.entete .calendrier-date.after {
  color: #17a2b8;
  border-color: #17a2b8;
  background: #fff;
  font-weight: normal;
}
.entete .calendrier-date.before {
  border-left: 1px solid;
}
.entete .calendrier-date.after {
  border-right: 1px solid;
}
.entete .calendrier-date.impossible,
.entete .calendrier-date.impossible:hover,
.entete .calendrier-date.before.impossible:hover,
.entete .calendrier-date.after.impossible:hover {
  color: #777;
  border-color: #777;
  background: #ccc;
  font-weight: normal;
  cursor: auto;
} 
.filtres {
  margin: 18px auto;
}
.filtres div span {
  color: #fff;
  font-size: 0.84em;
  display: inline-block;
  margin: 7px 7px 0 7px;
}
.entete div span div {
  color: #333;
  font-weight: 600;
}
.filtre-bouton {
  border-radius: 12px;
  height: 36px;
  border: 1px solid;
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 3px;
  width: 90%;
  background-color: #777;
  border-color: white;
  cursor: pointer;
}
.filtre-bouton.active,
.filtre-bouton.active-male {
  background-color: #17a2b8;
  border-color: white;
}
.filtre-bouton.active-female {
  background-color: #fd6c9e;
  border-color: white;
}
</style>
